import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingPageComponent} from '@app/modules/landing-page/pages/landing-page/landing-page.component';
import {AccessDeniedComponent} from '@app/modules/landing-page/pages/access-denied.component';
import {AuthGuard} from '@auth0/auth0-angular';
import {FirstLoginPageComponent} from '@app/modules/landing-page/pages/first-login-page/first-login-page.component';
import {GetCustomComponent} from '@app/modules/landing-page/pages/get-custom/get-custom.component';
import {getCustomGuard} from '@app/modules/landing-page/guards/get-custom.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: LandingPageComponent,
    data: {
      showBasket: false,
      menuButtons: [],
    },
    canActivate: [AuthGuard],
  },
  {
    path: 'first-login',
    pathMatch: 'full',
    component: FirstLoginPageComponent,
    data: {
      showBasket: false,
      menuButtons: [],
    },
    canActivate: [],
  },
  {
    path: 'access-denied',
    pathMatch: 'full',
    component: AccessDeniedComponent,
    data: {
      showBasket: false,
      menuButtons: [],
    },
    canActivate: [],
  },
  {
    path: 'get-custom',
    pathMatch: 'full',
    component: GetCustomComponent,
    data: {
      showBasket: false,
      menuButtons: [],
    },
    canActivate: [getCustomGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class LandingPageRoutingModule {}
