import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {take} from 'rxjs/operators';
import {map} from 'rxjs';
import {hasNoCustomAccess} from '@app/modules/auth/models/user.model';
import {AuthUserService} from '@app/modules/auth/services/auth-user.service';

export const getCustomGuard: CanActivateFn = () => {
  const router = inject(Router);
  const userService = inject(AuthUserService);

  return userService.getUser().pipe(
    take(1),
    map((user) => (hasNoCustomAccess(user) ? true : router.parseUrl('/')))
  );
};
