import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import * as datasetBasket from '@app/modules/basket/store/dataset/basket-dataset.selectors';
import {hasCustomAccess, hasNoCustomAccess, isExternal, isInternal} from '@app/modules/auth/models/user.model';
import {AppState} from '@app/store';
import {environment} from '@env/environment';
import {Store} from '@ngrx/store';
import {EMPTY, Observable, Subject} from 'rxjs';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {BasketDialogService} from '@app/modules/basket/services/basket-dialog.service';
import {AuthUserService} from '@app/modules/auth/services/auth-user.service';

type RouteData = { showBasket: boolean; menuButtons: ButtonData[] };
type ButtonData = { linkTo: string; label: string, type: 'ABSOLUTE' | 'RELATIVE', allowNonCustom: boolean };

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent implements OnInit, OnDestroy {
  initials$: Observable<string> = EMPTY;
  status$: Observable<{
    showBasket: boolean;
    menuButtons: ButtonData[];
  }> = EMPTY;
  isInternal = false;
  isExternal$: Observable<boolean> = EMPTY;
  hasCustomAccess = false;
  hasNoCustomAccess$: Observable<boolean> = EMPTY;

  private onDestroy$ = new Subject<void>();

  basketSize = 0;
  showBanner = environment.name !== 'prod';

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private userService: AuthUserService,
    private basketDialogService: BasketDialogService,
  ) {
  }

  ngOnInit(): void {
    this.isExternal$ = this.userService
      .getUser().pipe(
        map((user) => isExternal(user)),
      );

    this.hasNoCustomAccess$ = this.userService
      .getUser().pipe(
        map((user) => hasNoCustomAccess(user)),
      );

    this.userService
      .getUser().pipe(
        map((user) => isInternal(user)),
        takeUntil(this.onDestroy$)
      ).subscribe((isInternal) => this.isInternal = isInternal);

    this.userService
      .getUser().pipe(
      map((user) => hasCustomAccess(user)),
      takeUntil(this.onDestroy$)
    ).subscribe((hasCustomAccess) => this.hasCustomAccess = hasCustomAccess);

    this.store
      .select(datasetBasket.selectTotal)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((datasetTotal) => (this.basketSize = datasetTotal));

    const routeData$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(
        () =>
          this.router.routerState.snapshot.root.firstChild?.data as RouteData,
      ),
    );

    this.status$ = routeData$.pipe(
      map((routeData) => ({
        showBasket: routeData?.showBasket,
        menuButtons: routeData?.menuButtons,
      })),
    );

    this.initials$ = this.userService
      .getUser()
      .pipe(map((user) => user.initials));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  openFeedback(): void {
    window.open(
      'https://bcgcloudeur.sharepoint.com/:x:/s/Q-Communities/EUV7gz9IsB1DmhMgAO7vekIBSFwMvonpYkdquVmCHUsxhg?e=gbynlF',
    );
  }

  openSynonymsFile(): void {
    window.open(
      'https://bcgcloudeur.sharepoint.com/:x:/s/Q-Communities/EYSct3hF-PFBsnmhG6YsFLIBhtVE31ZfcOSworCJLjGU3g?e=PffCFk',
    );
  }

  openChangelog(): void {
    this.isExternal$.pipe(take(1)).subscribe((isExternal) => {
      const linkToOpen = isExternal
        ? 'https://prod-public-docs-bucket.s3.eu-west-1.amazonaws.com/eqosphere_external_changelog_20241204.pdf'
        : 'https://bcgcloudeur.sharepoint.com/:w:/s/Q-Communities/EWJMf5yksO1Jk-g56lnmwQgBnTdQJN6gET2BSGvE75J8PA?e=TzPtgR';
      window.open(linkToOpen);
    });
  }

  logout(): void {
    this.userService.logout();
  }

  changePwd(): void {
    this.userService.changePassword();
  }

  openImportDialog(): void {
    this.basketDialogService
      .openImportDialog()
      .afterClosed()
      .subscribe((success: boolean | undefined) => {
        if (success === true) {
          void this.router.navigate(['basket']);
        }
      });
  }

  openExportDialog(): void {
    this.basketDialogService.openExportDialog();
  }

  openUpgradeDialog(): void {
    this.basketDialogService
      .openUpgradeDialog()
      .afterClosed()
      .subscribe((success: boolean | undefined) => {
        if (success === true) {
          void this.router.navigate(['basket']);
        }
      });
  }
}
