import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {LandingPageComponent} from './pages/landing-page/landing-page.component';
import {LandingPageRoutingModule} from '@app/modules/landing-page/landing-page-routing.module';
import {MaterialModule} from '@app/modules/material/material.module';
import {FlexLayoutModule} from '@ngbracket/ngx-layout';
import {AccessDeniedComponent} from '@app/modules/landing-page/pages/access-denied.component';
import {FirstLoginPageComponent} from '@app/modules/landing-page/pages/first-login-page/first-login-page.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  LandingPageSearchSectionComponent
} from '@app/modules/landing-page/components/landing-page-search-section/landing-page-search-section.component';
import {LandingPageSectionComponent} from '@app/modules/landing-page/components/landing-page-section.component';
import {
  LandingPageModelSectionComponent
} from './components/landing-page-model-section/landing-page-model-section.component';
import {
  DatabaseFilterComponent
} from './components/landing-page-search-section/database-filter/database-filter.component';
import {
  GeographyFilterComponent
} from '@app/modules/landing-page/components/landing-page-search-section/geography-filter/geography-filter.component';
import {FilterComponent} from './components/landing-page-search-section/filter/filter.component';
import {
  LandingPageWhatsNewSectionComponent
} from './components/landing-page-whats-new-section/landing-page-whats-new-section.component';
import {
  LandingPageAboutSectionComponent
} from './components/landing-page-about-section/landing-page-about-section.component';
import {UiModule} from '@app/modules/ui/ui.module';
import {
  LandingPageSectionContentComponent
} from '@app/modules/landing-page/components/landing-page-section-content.component';
import {
  LandingPageSectionContentTwoColumnsComponent
} from './components/landing-page-section-content-two-columns.component';
import {GetCustomComponent} from '@app/modules/landing-page/pages/get-custom/get-custom.component';

@NgModule({
  declarations: [
    LandingPageComponent,
    AccessDeniedComponent,
    FirstLoginPageComponent,
    LandingPageSearchSectionComponent,
    LandingPageSectionComponent,
    LandingPageModelSectionComponent,
    DatabaseFilterComponent,
    GeographyFilterComponent,
    FilterComponent,
    LandingPageWhatsNewSectionComponent,
    LandingPageAboutSectionComponent,
    LandingPageSectionContentComponent,
    LandingPageSectionContentTwoColumnsComponent,
    GetCustomComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    LandingPageRoutingModule,
    UiModule,
  ],
})
export class LandingPageModule {}
