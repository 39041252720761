import {Component, Input, OnChanges, OnInit, SimpleChanges,} from '@angular/core';
import {addDataset, exportBasket, removeDataset,} from '@app/modules/basket/store/dataset/basket-dataset.actions';
import {isInBasket} from '@app/modules/basket/store/dataset/basket-dataset.selectors';
import {
  DatasetSearchResultMapperService
} from '@app/modules/dataset/mappers/dataset-search-result/dataset-search-result-mapper.service';
import {Dataset} from '@app/modules/dataset/models/dataset.model';
import {AppState} from '@app/store';
import {Store} from '@ngrx/store';
import {EMPTY, Observable, of} from 'rxjs';
import {environment} from '@env/environment';
import {map} from 'rxjs/operators';
import {hasCustomAccess} from '@app/modules/auth/models/user.model';
import {AuthUserService} from '@app/modules/auth/services/auth-user.service';
import {RumService} from '@app/modules/rum/rum.service';

@Component({
  selector: 'app-dataset-detail-header',
  templateUrl: './dataset-detail-header.component.html',
  styleUrl: './dataset-detail-header.component.scss',
})
export class DatasetDetailHeaderComponent implements OnChanges, OnInit {
  @Input()
  dataset!: Dataset;

  isInBasket$: Observable<boolean | undefined> = of(undefined);
  hasCustomAccess$: Observable<boolean> = EMPTY;

  constructor(
    private store: Store<AppState>,
    private mapper: DatasetSearchResultMapperService,
    private userService: AuthUserService,
    private rumService: RumService,
  ) {}

  ngOnInit(): void {
    this.hasCustomAccess$ = this.userService
      .getUser()
      .pipe(map((user) => hasCustomAccess(user)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('dataset' in changes) {
      this.isInBasket$ = this.store.select(isInBasket(this.dataset.id));
    }
  }

  addToBasket(): void {
    const dataset = this.mapper.fromDataset(this.dataset);
    this.store.dispatch(addDataset({ dataset }));
  }

  removeFromBasket(): void {
    const dataset = this.mapper.fromDataset(this.dataset);
    this.store.dispatch(removeDataset({ dataset }));
  }

  customize(): void {
    const url = `${environment.eQoterraUrl}/activity-modeling?customizeId=${this.dataset.id}`;
    this.rumService.triggerDatasetCustomizeEvent(this.dataset, url);
    window.open(url, '_blank');
  }

  onExport(): void {
    const wantedGroups = [...new Set(this.dataset.referenceProduct.assessmentGroups.map(x => x.name))];
    this.store.dispatch(
      exportBasket({
        options: {
          datasetIds: [this.dataset.id],
          groups: wantedGroups,
          withExchangeContribution: true,
          withObsoleteFlagContribution: true,
          withSplitGasContribution: true,
          withSplitActivityContribution: true,
          withEmbeddedProducts: true,
        },
      }),
    );
  }
}
