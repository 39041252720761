import {Injectable} from '@angular/core';
import {User, UserConnection} from '@app/modules/auth/models/user.model';
import {IdToken} from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class UserMapperService {
  fromIdToken(idToken: IdToken | null | undefined): User {
    if (idToken === null) {
      throw new Error('idToken is null');
    }
    if (idToken === undefined) {
      throw new Error('idToken is undefined');
    }
    if (idToken.name === undefined) {
      throw new Error('idToken.name is undefined');
    }
    if (!this.isConnection(idToken.connection)) {
      throw new Error(
        `idToken.connection "${String(idToken.connection)}" is invalid`,
      );
    }
    return {
      initials: idToken.name.substring(0, 3).toUpperCase(),
      connection: idToken.connection,
      company: idToken.company as string,
      roles: idToken.user_roles === undefined ? [] : idToken.user_roles as string[],
    };
  }

  private isConnection(connection: unknown): connection is UserConnection {
    return (
      typeof connection === 'string' &&
      Object.values<string>(UserConnection).includes(connection)
    );
  }
}
