import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {PageComponent} from '@app/modules/core/components/page-component';
import {AuthUserService} from '@app/modules/auth/services/auth-user.service';

@Component({
  templateUrl: './get-custom.component.html',
  styleUrl: './get-custom.component.scss',
})
export class GetCustomComponent implements PageComponent {
  readonly pageName = 'get-custom';

  constructor(
    private userService: AuthUserService,
    private location: Location,
  ) {}

  onLoginAgain(): void {
    this.userService.logout();
  }

  onBack(): void {
    this.location.back();
  }
}
