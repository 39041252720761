<section class="mat-elevation-z24">
  <mat-icon svgIcon="custom-access-level"></mat-icon>
  <h1>eQosphere Custom: Now Available! </h1>
  <p>eQosphere Custom is now live and ready to use.</p>
  <p>
    Interested in upgrading your subscription plan to access this feature?
    We're here to help! Contact us at
    <a href="mailto:eqosphere.support@quantis.com"
      >eqosphere.support&#64;quantis.com</a
    > and our team will guide you through the process.
  </p>
  <p>We look forward to hearing from you!</p>
  <button mat-raised-button color="primary" (click)="onBack()">
    <strong>Go back</strong>
  </button>
  <mat-divider></mat-divider>
  <footer>
    If you believe this is an error, please try to
    <a (click)="onLoginAgain()">log in</a> again.
  </footer>
</section>
