<ng-container *ngIf="dataset">
  <div fxLayout="row" fxLayoutAlign="space-between top" fxFlex="1 1 auto">
    <app-copiable-id [copiableId]="dataset.id">
      <a [routerLink]="['.']">
        <h1>
          {{ dataset.referenceProduct.name }}
          <app-dataset-warning
            [datasetDescription]="dataset.description"
            [displayText]="false"
          ></app-dataset-warning>
        </h1>
        <p class="subtitle">Database: {{ dataset.description.database.simpleDisplayName }} </p>
      </a>
    </app-copiable-id>
    <div class="buttons" fxFlex="0 0 auto">
      <div fxLayoutAlign="row">
        <ng-container *ngIf="isInBasket$ | async; else notInBasketCase">
          <button (click)="removeFromBasket()" mat-raised-button>
            Remove from my basket
          </button>
        </ng-container>
        <ng-template #notInBasketCase>
          <button (click)="addToBasket()" color="primary" mat-raised-button>
            Add to my basket
          </button>
        </ng-template>
        <button *ngIf="hasCustomAccess$ | async" (click)="customize()" mat-raised-button>
          Customize
        </button>
        <button (click)="onExport()" mat-raised-button>
          Export dataset
        </button>
      </div>
    </div>
  </div>
</ng-container>
